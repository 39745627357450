<template>
  <div>
    <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    </v-overlay>

    <!-- =======================================================
                                Dialog
    ============================================================-->
    <v-dialog v-model="openDialog" width="500">
      <v-card>
        <v-card-title class="headline red white--text" primary-title>
          <v-spacer />Atenção
          <v-spacer />
        </v-card-title>
        <v-card-text class="pa-5">Deseja sair, todo o processo será encerrado</v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-5">
          <v-spacer></v-spacer>
          <v-btn text @click="openDialog = false">Não</v-btn>
          <v-spacer></v-spacer>
          <v-btn text to="/pages/user">Sim</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- =======================================================
                                Snackbar
    ============================================================-->
    <v-snackbar v-model="snackbar.active">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.active = false">Cancelar</v-btn>
      </template>
    </v-snackbar>

    <!-- =======================================================
                                Main
    ============================================================-->
    <v-spacer />
    <v-container id="createEditUser" fluid tag="section">
      <v-row justify="center">
        <v-col cols="6">
          <v-form>
            <v-container>
              <v-row justify="center">
                <v-col cols="12">
                  <v-text-field v-model="name" label="Nome" outlined required></v-text-field>
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :rules="[rules.required, rules.email]"
                    outlined
                    required
                  ></v-text-field>
                  <v-select
                    v-model="profileId"
                    outlined
                    :items="profiles"
                    :rules="[v => !!v || 'Escolha um perfil.']"
                    label="Perfil"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-row justify="space-between">
                    <v-btn text @click="cancel()">Cancelar</v-btn>
                    <v-btn color="primary" @click="create()">Salvar</v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-spacer />
  </div>
</template>

<script>
import userService from "../../../../service/userService";

export default {
  name: "CreateEditUser",
  data: () => ({
    name: "",
    email: "",
    profileId: null,
    profiles: [],
    overlay: false,
    openDialog: false,
    snackbar: {
      text: "",
      active: false
    },
    rules: {
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "E-mail invalido.";
      }
    }
  }),
  methods: {
    create() {
      if (this.validateData()) {
        
        const params = {
          name: this.name,
          email: this.email,
          profile_id: this.profileId
        };

        this.overlay = true;
        if (this.id) {
          userService.update(this.id, params).then(res => {
            if (res.data.status === "error") {
              this.snackbar.text = res.data.message;
              this.snackbar.active = true;
              this.overlay = false;
              return;
            }

            this.overlay = false;
            this.$router.push({ path: "/pages/user" });
          });
        } else {
          userService.create(params).then(res => {
            if (res.data.status === "error") {
              this.snackbar.text = res.data.message;
              this.snackbar.active = true;
              this.overlay = false;
              return;
            }

            this.overlay = false;
            this.$router.push({ path: "/pages/user" });
          });
        }
      }
    },
    validateData() {
      if (!this.name) {
        this.snackbar.text = "O nome não pode ser vazio.";
        this.snackbar.active = true;
        return false;
      } else if (!this.email) {
        this.snackbar.text = "O email não pode ser vazio.";
        this.snackbar.active = true;
        return false;
      } else if (!this.profileId) {
        this.snackbar.text = "O perfil não pode ser vazio.";
        this.snackbar.active = true;
        return false;
      }

      return true;
    },
    getProfiles() {
      userService.getAllProfile().then(res => {
        this.profiles = res.data.map(profile => {
          return {
            value: profile.id,
            text: profile.name === "ADMIN" ? "Administrador" : "Colaborador"
          };
        });
      });
    },
    cancel(){
    this.openDialog = true
  },
  },
  mounted() {
    this.id = parseInt(this.$router.history.current.params.id);
    this.getProfiles();

    if (this.id) {
      userService.get({ id: this.id }).then(res => {
        const data = res.data[0];
        this.name = data.name;
        this.email = data.email;
        this.profileId = data.profile_id;
      });
    }
  }
};
</script>


<style lang="sass" scoped>
</style>